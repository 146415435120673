export function getImagePath(nombre) {
    try {
        return require(`@/assets/img/${nombre}`)
    } catch (e) {

    }
}

export function bloquearPantalla(texto){

    $.blockUI({
        message:  (texto == null) ? '' :

            `<h4 class="text-dark">${texto}</h4>
            <img src="${getImagePath('LogoEmporioSport.svg')}" alt="Emporio cargando"  width = "200px"/>`,
        css: {
            border: 'none',
            padding: '15px',
            backgroundColor: '#f2f4f5',
            '-webkit-border-radius': '10px',
            '-moz-border-radius': '10px',

            color: '#fff'
        } });
}

export function desbloquearPantalla(){
    $.unblockUI();
}

export function formatoMoneda(numero) {
    return numero.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
}

export function isset(variable) {
    return typeof variable !== 'undefined' && variable !== null;
}

export function getBaseUrl() {
    const baseElement = document.querySelector('base');
    if (baseElement) {
        return baseElement.href;
    }
    return `${window.location.protocol}//${window.location.host}/`;
}

export function empty(value) {
    // Check for null or undefined
    if (value === null || value === undefined) {
        return true;
    }

    // Check for string or array with length 0
    if (typeof value === 'string' || Array.isArray(value)) {
        return value.length === 0;
    }

    // Check for object with no properties
    if (typeof value === 'object') {
        for (let key in value) {
            if (Object.prototype.hasOwnProperty.call(value, key)) {
                return false;
            }
        }
        return true;
    }

    // Check for number 0 or boolean false
    if (typeof value === 'number' || typeof value === 'boolean') {
        return value === 0 || value === false;
    }

    return false;
}

function base_url(path = '') {
    // Obtener la URL base del sitio web
    const baseUrl = `${window.location.protocol}//${window.location.host}/`;

    // Concatenar la ruta proporcionada
    const fullUrl = new URL(path, baseUrl);

    return fullUrl.href;
}

function tieneParametrosGET(url) {
    // Crear un objeto URL a partir de la cadena de URL proporcionada
    const urlObj = new URL(url);

    // Verificar si la propiedad search no está vacía
    return urlObj.search !== '';
}


export function getUrl(){

// Ejemplo de URL con un fragmento que incluye parámetros GET
    const url = window.location.href;


// Crear un objeto URL a partir de la cadena de la URL
    const urlObj = new URL(url);

// Obtener la parte después del '#'
    const hash = urlObj.hash;

// Comprobar si hay parámetros en el fragmento
    const fragmentParts = hash.split('?');
    let params = new URLSearchParams();

    if (fragmentParts.length > 1) {
        // Extraer los parámetros del fragmento
        params = new URLSearchParams(fragmentParts[1]);
    }

// Acceder a los parámetros individuales
    const idm = params.get('idm');
    if(!empty(idm)){

        localStorage.setItem('medio',idm)
        return idm
    }

    return null

}

export function esHoraEnRango() {
    // Obtiene la fecha y hora actual
    const ahora = new Date();

    // Verifica si es domingo
    const diaSemana = ahora.getDay(); // 0 es domingo, 1 es lunes, etc.
    if (diaSemana === 0) {
        return false;
    }

    // Define las horas de inicio y fin
    const horaInicio = new Date();
    horaInicio.setHours(10, 30, 0); // 10:30 AM

    const horaFin = new Date();
    horaFin.setHours(18, 30, 0); // 6:30 PM

    // Compara la hora actual con el rango especificado
    return ahora >= horaInicio && ahora <= horaFin;
}


export const fechaCastellano = (fecha,conhora = true)=> {
    const fechaObjeto = new Date(fecha);

    // Obtener el nombre del día de la semana
    const diasSemana = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
    const diaSemana = diasSemana[fechaObjeto.getDay()];

    // Obtener el número del día
    const dia = fechaObjeto.getDate();

    // Obtener el nombre del mes
    const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
    const mes = meses[fechaObjeto.getMonth()];

    // Obtener el año
    const anio = fechaObjeto.getFullYear();

    // Obtener la hora y el minuto
    let hora = fechaObjeto.getHours();
    const minuto = fechaObjeto.getMinutes();

    // Determinar si es AM o PM
    const amPM = hora >= 12 ? 'PM' : 'AM';

    // Convertir la hora al formato de 12 horas
    if (hora > 12) {
        hora -= 12;
    } else if (hora === 0) {
        hora = 12;
    }

    if(conhora){
        // Construir la cadena de resultado
        return `${diaSemana} ${dia} de ${mes} del ${anio} a las ${hora}:${minuto.toString().padStart(2, '0')} ${amPM}`;
    }

    // Construir la cadena de resultado
    return `${diaSemana} ${dia} de ${mes} del ${anio}`;
}