import {createRouter, createWebHashHistory, createWebHistory} from "vue-router"

const routes = [
    {
        path: '/', component: () => import('@/module/optionsEmporio/pages/optionsComponent.vue'),
        name: 'Home',
        meta:{ title: 'Cotiza | Emporio Sport ' },
    },
    {
       path: '/deportes', component: () => import('@/module/sports/pages/sportListComponent.vue'),
       name: 'Deportes',
       meta:{ title: 'Deportes | Emporio Sport ' },
    },
    {
        path: '/tipo-jugador', component: () => import('@/module/gender/uniformTypeComponent.vue'),
        name: 'PlayerType',
        meta:{ title: 'Tipo de jugador | Emporio Sport ' },
    },
    {
        path: '/personaliza-uniforme', component: () => import('@/module/selectPack/selectPackComponent.vue'),
        name: 'Personalizar',
        meta:{ title: 'Personalizar Uniformes | Emporio Sport ' },
    },
    {
        path: '/comparar-calidades', component: () => import('@/module/compareQualieties/compareQualietiesComponent.vue'),
        name: 'Calidades',
        meta:{ title: 'Comparar Calidad | Emporio Sport ' },
    },
    {
        path: '/detalles-uniformes', component: () => import('@/module/uniformDetails/uniformDetailsComponent.vue'),
        name: 'Detalles de uniformes',
        meta:{ title: 'Detalles de uniformes | Emporio Sport ' },
    },
    {
        path: '/cotizacion/:folio', component: () => import('@/module/price/priceComponent.vue'),
        name: 'Cotización',
        meta: { title: 'Detalles de uniformes | Emporio Sport '}
    },
    {
        path: '/accesorios',component:() => import('@/module/accesorys/accesoryComponent.vue'),
        name: 'Accesorios',
        meta: { title: 'Accesorios | Emporio Sport ' }
    },
    {
        path: '/cuestionario-empresas',component:() => import('@/module/questionnaire/questionnaireComponent.vue'),
        name: 'Cuestionario-Empresas',
        meta: { title: 'Cuestionario Empresas | Emporio Sport' }
    },
    {
        path: '/not-found', component:() => import('@/components/404.vue'),
        name: 'NotFound',
        meta: { title: '404 | Emporio Sport ' }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/not-found'
    }

]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router